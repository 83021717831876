<template>
 
    <div class="shadow p-3 mb-2 bg-white rounded">
      <p class="fw-bold m-2">{{ title }}</p>
    </div>
 
</template>

<script>

export default {
  props: {
    title: String,
    id: String
  }, 
  setup() {

  }
}

</script>

<style scoped>
p {
  color: rgb(211, 15, 15);
}
</style>