<template>
  <nav class="nav">
    <router-link to="/dashboard">
      <img class="navbar-brand icon" alt="Logo" src="@/assets/logo-txt.svg">
    </router-link>
    <router-link class="nav-item nav-link " to="/profile">
      <i class="bi bi-person-fill align-middle"></i>
      <span>Profile</span>
    </router-link>
  </nav>
</template>

<style scoped>

.nav-item:hover {
  color: rgb(130, 8, 8);
}

.nav-item:active {
  color: rgb(130, 8, 8);
}

</style>