<template>
  <router-view/>
</template>

<script>
import { auth } from '@/firebaseConfig.js'
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  
  setup() {
    const store = useStore()

    const user = computed(() => {
      return store.getters.user;
    });

    auth.onAuthStateChanged(user => {
      store.dispatch("fetchUser", user);
      store.dispatch("fetchGroups", user.uid);
    });

    return { user, store }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url('@/assets/background.svg');
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.container-fluid {
  height: 100vh;
  padding: 0px;
}

.icon {
  width: 150px;
  padding-left: 20px;
}

i {
  padding-right: 10px;
  font-size: 25px;
}

a {
  text-decoration: none;
}

nav {
  padding: 20px;
  justify-content: space-between;
  background-color: rgb(211, 15, 15);
}

nav a {
  font-weight: bold;
  color: white;
}

.nav-item {
  color: white;
}

.btn-pri {
  background-color: rgb(211, 15, 15); 
  color: white;
  border-radius: 8px;
}

.btn-pri:hover {
  background-color: rgb(108, 9, 9);
  color: white;
  border-radius: 8px;
}

.btn-pri:focus {
  background-color: rgb(92, 7, 7);
  color: white;
  border-radius: 8px;
}

.footer {
  background-image: url('@/assets/footer.svg');
  background-size: cover;
  background-position: center;
  height: 40vh;
  margin-top: 20px;
}

.emp {
  font-style: italic;
  color: #6a6a6a;
}

</style>
