<template>
  <div class="container-fluid d-flex flex-column justify-content-between">
    <div>
      <Navigation/>
      <div class="container">
          <div class="mobile-stack hstack mt-5 mb-3">
            <div class="col">
              <h2 class="text-start">Grupper</h2>
            </div>
          <div>
            <button class="btn btn-pri btn-sm px-3" @click="showJoinModal = true">
              <i class="bi bi-people-fill align-middle"></i>
              <span>Bliv medlem</span>
            </button>
            <button class="btn btn-pri btn-sm ms-3 px-3" @click="showAddModal = true">
              <i class="bi bi-plus-lg align-middle"></i>
              <span>Opret gruppe</span>
            </button>
          </div>
        </div>

        <div v-if="groups.length !== 0">
          <li v-for="group in groups" :key="group.id">
            <router-link :to="{name: 'group', params: {id: group.id}}">
              <GroupItem :title="group.name" :id="group.id"/>
            </router-link>
          </li>
        </div>
        <p class="emp m-5" v-else>Endnu ingen grupper...</p>
          
      
          <transition name="modal">
            <ModalComponent v-if="showJoinModal" @close="showJoinModal = false">
            <template v-slot:modal-content>
              <h3>Bliv medlem af en gruppe</h3>
              <p>Indtast gruppekoden for at få adgang</p>
              <input v-model="joinGroupCode" class="form-control mb-3" placeholder="gruppekode"/>
              <button class="btn btn-pri" @click="joinGroup">Bliv medlem</button>
            </template>
          </ModalComponent>
        </transition>

        <transition name="modal">
            <ModalComponent v-if="showAddModal" @close="showAddModal = false">
            <template v-slot:modal-content>
              <h3>Opret en ny gruppe</h3>
              <input v-model="groupName" class="form-control mb-3" placeholder="gruppenavn"/>
              <button class="btn btn-pri" @click="addGroup">Opret</button>
            </template>
          </ModalComponent>
        </transition>

      </div>
     
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import GroupItem from '@/components/GroupItem.vue'
import ModalComponent from '@/components/ModalComponent.vue'
import Navigation from "@/components/NavigationComponent.vue";

export default {
  components: {
    GroupItem, 
    ModalComponent,
    Navigation
  },
  setup() {
    const store = useStore()
    
    const showAddModal = ref(false)
    const showJoinModal = ref(false)
    const groupName = ref("")
    const joinGroupCode = ref("")
    const error = ref(null)

    const userName = computed(() => {
      return store.getters.user.data.uid;
    });

    const groups = computed(() => {
      return store.getters.user.groups;
    });

    const addGroup = async () => {
      let groupId = Math.random().toString(36).slice(2)
      try {
        await store.dispatch('writeGroupData', {
          groupId: groupId,
          userId: store.getters.user.data.uid,
          groupName: groupName.value,
          userName: store.getters.user.data.displayName
        })
      }
      catch (err) {
        error.value = err.message
        console.log(err)
      }
      try {
        await store.dispatch('writeGroupDataToUser', {
          groupId: groupId,
          userId: store.getters.user.data.uid,
          groupName: groupName.value
        })
      }
      catch (err) {
        console.log(err)
      }
      showAddModal.value = false
    }

    const joinGroup = async () => {
      try {
        await store.dispatch('writeJoinGroup', { 
          groupId: joinGroupCode.value,
          userId: store.getters.user.data.uid,
          userName: store.getters.user.data.displayName
        })
      }
      catch (err) {
        error.value = err.message
        console.log(err)
      }
      // try {
      //   await store.dispatch('writeGroupDataToUser', {
      //     groupId: groupId,
      //     userId: store.getters.user.data.uid,
      //     groupName: groupName.value
      //   })
      // }
      // catch (err) {
      //   console.log(err)
      // }
      showJoinModal.value = false
    }

  return { showAddModal, showJoinModal, groupName, joinGroupCode, addGroup, joinGroup, userName, groups }

  }
}

</script>

<style scoped>

.container {
  max-width: 700px;
}

h2 {
  color: rgb(211, 15, 15);
  font-weight: bold;
}

h3 {
  color: rgb(211, 15, 15);
}

i {
  font-size: 20px;
}

li {
  text-decoration: none;
  list-style-type: none;
}

@media (max-width: 600px) {
  .mobile-stack { 
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch;
 }
 .mobile-btn {
  align-self: stretch;
 }
}


</style>