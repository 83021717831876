import { createStore } from 'vuex'
import { auth } from '@/firebaseConfig.js'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile
} from 'firebase/auth'
import { getDatabase, ref, set, onValue, push, remove, update } from "firebase/database";

const store = createStore({
  state: {
    user: {
      loggedIn: false,
      data: { uid: "", displayName: "" },
      groups: []
    },
    group: {
      id: "",
      members: [],
      memberNames: [],
      name: "",
      closed: false
    },
    wishes: {
      id: "",
      data: []
    }, 
    nisse: {
      id: "",
      data: [{ id: "", data: [] }]
    }
  },
  getters: {
    user: state => {
      return state.user
    },
    group: state => {
      return state.group
    },
    wishes: state => {
      return state.wishes
    },
    nisse: state => {
      return state.nisse
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_GROUPS(state, data) {
      state.user.groups = data;
    },
    SET_GROUP(state, data) {
      state.group = data
    },
    SET_WISHES(state, data) {
      state.wishes = data
    },
    SET_NISSE(state, data) {
      state.nisse = data
    },
    CLEAR_NISSE(state) {
      state.nisse = {id: "", data: [{ id: "", data: [] }]}
    }
  },
  actions: {
    async register(context, { email, password, name }) {
      const response = await createUserWithEmailAndPassword(auth, email, password)
      if (response) {
        console.log(response.user)
        context.commit('SET_USER', response.user)
        updateProfile(response.user, {
          displayName: name
        })
        const db = getDatabase();
        set(ref(db, 'users/' + response.user.uid), {
          name: name,
          email: email
        });
      } else {
        throw new Error('Unable to register user')
      }
    },

    async logIn(context, { email, password }) {
      const response = await signInWithEmailAndPassword(auth, email, password)
      if (response) {
        context.commit('SET_USER', response.user)
      } else {
        throw new Error('login failed')
      }
    },

    async logOut(context) {
      await signOut(auth)
      context.commit('SET_USER', null)
    },

    async fetchUser(context, user) {
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER", user);
      } else {
        context.commit("SET_USER", null);
      }
    },

    async fetchGroups(context, userId) {
      const db = getDatabase();
      const groupRef = ref(db, 'users/' + userId + '/groups');
      onValue(groupRef, (snapshot) => {
        let list = []
        snapshot.forEach((childSnapshot) => {
          list.push({
            id: childSnapshot.key,
            name: childSnapshot.child('name').val()
          })
        });
        context.commit("SET_GROUPS", list);
      });
    },

    async fetchGroup(context, groupId) {
      const db = getDatabase();
      const groupRef = ref(db, 'groups/' + groupId);
      onValue(groupRef, (snapshot) => {
        const data = snapshot.val();
        context.commit("SET_GROUP", data);
        
      });
    },

    async fetchWishes(context, { groupId, userId }) {
      const db = getDatabase();
      const groupRef = ref(db, 'users/' + userId + '/groups/' + groupId + '/wishes');
      onValue(groupRef, (snapshot) => {
        let list = []
        snapshot.forEach((childSnapshot) => {
          list.push({
            id: childSnapshot.key, data: childSnapshot.val()
          })
        });
        context.commit("SET_WISHES", list)
      })

    },

    async fetchNisse(context, { groupId, userId }) {
      context.commit("CLEAR_NISSE")
      let nisse = { name: "", data: [] }

      const db = getDatabase();
      const userRef = ref(db, 'users/' + userId + '/name');
      onValue(userRef, (snapshot) => {
          const name = snapshot.val();
          nisse.name = name
      })

      const wishesRef = ref(db, 'users/' + userId + '/groups/' + groupId + '/wishes');
      onValue(wishesRef, (snapshot) => {
        let list = []
        snapshot.forEach((childSnapshot) => {
          list.push({
            id: childSnapshot.key, data: childSnapshot.val()
          })
        });
        nisse.data = list
        context.commit("SET_NISSE", nisse)
      })

    },

    async writeGroupData(context, { groupId, groupName, userId, userName }) {
      const db = getDatabase();
      const groupRef = ref(db, 'groups/' + groupId)
      set(groupRef, {
        name: groupName,
        open: true,
        admin: userId
      })
      set(ref(db, 'groups/' + groupId + '/members/' + userId), userName)
    },

    async writeJoinGroup(context, { groupId, userId, userName }) {
      const db = getDatabase();
      set(ref(db, 'groups/' + groupId + '/members/' + userId), userName)

      const groupRef = ref(db, 'groups/' + groupId + '/name');
      onValue(groupRef, (snapshot) => {
        const data = snapshot.val();
        const groupRef = ref(db, 'users/' + userId + '/groups/' + groupId)
        set(groupRef, { name: data })
      })
    },

    async writeGroupDataToUser(context, { groupId, userId, groupName }) {
      const db = getDatabase();
      const groupRef = ref(db, 'users/' + userId + '/groups/' + groupId)
      set(groupRef, { name: groupName })
    },

    async writeWish(context, { groupId, userId, title, description, link }) {
      const db = getDatabase();
      const groupRef = ref(db, 'users/' + userId + '/groups/' + groupId + '/wishes')
      push(groupRef, {
        title: title,
        description: description,
        link: link
      })
    },

    async removeWish(context, { groupId, userId, wishId }) {
      const db = getDatabase();
      const groupRef = ref(db, 'users/' + userId + '/groups/' + groupId + '/wishes/' + wishId)
      remove(groupRef);
    },

    async writePair(context, { users1, users2, groupId }) {
      const db = getDatabase();
      for (var i = 0; i < users1.length; ++i) {
        set(ref(db, 'groups/' + groupId + '/pairs/' + users1[i]), users2[i])
      }
      update(ref(db, 'groups/' + groupId), { open: false})
    }
  }
})

export default store