<template>
  <div class="container-fluid d-flex flex-column justify-content-between">
    <div>
      <Navigation/>
      <div class="container">
        <div class="mobile-stack hstack mt-5 mb-3">

          <div class="col hstack">
            <router-link to="/dashboard">
              <i class="bi bi-arrow-left pe-3"></i>
            </router-link>
            <h2>{{group.name}}</h2>
          </div>

          <div class="col hstack mobile-space mt-3">
            <div :class="{tab: selected === 0}">
              <p class="item-tab px-3 align-middle mb-2" :class="{ active: selected === 0 }" @click="selected = 0">Oversigt</p>
            </div>
            <div :class="{tab: selected === 1}">
              <p class="item-tab px-3 mb-2" :class="{ active: selected === 1 }" @click="[selected = 1, getNisse()]">Min nisse</p>
            </div>
            <div :class="{tab: selected === 2}">
              <p class="item-tab px-3 mb-2" :class="{ active: selected === 2 }" @click="[selected = 2, getWishes()]">Min ønskeliste</p>
            </div>
            
          </div>
        </div>

      <div>
        <div v-if="selected == 0">
          <div class="shadow p-3 mb-2 bg-white rounded">
            <div v-if="group.open" class="hstack">
              <div class="col">
                <h5>Gruppen er åben</h5>
                <p>Inviter venner ved at give med den unikke gruppekode</p>
                <h5>{{groupId}}</h5>
              </div>
    
              <div class="mx-4">
                <i class="bi bi-unlock-fill align-middle"></i>
                <p class="caption">ÅBEN</p>
                <button v-if="user.data.uid == group.admin" class="btn btn-pri" @click="closeGroup">Luk gruppe</button>
              </div>

            </div>
            <div v-else class="hstack">
              <div class="col">
                <h5>Gruppen er lukket</h5>
                <p>Nisserne er blevet fordelt</p>
              </div>
    
              <div class="mx-4">
                <i class="bi bi-lock-fill align-middle"></i>
                <p class="caption">LUKKET</p>
              </div>

            </div>
            
          </div>
          <div class="shadow p-3 mb-2 bg-white rounded">
            <h5>Medlemmer</h5>
            <ul class="p-0">
              <li v-for="name in group.members" :key="name">
                <img class="profilePic" src="@/assets/elf.png"/>
                {{ name }}
              </li>
            </ul>

          </div>
        </div>
        <div v-else-if="selected == 1">
          <div class="shadow p-3 mb-2 bg-white rounded">
            <h5 v-if="!group.open">Din nisse er {{ nisse.name }}</h5>
            <h5 v-else>Nisser er ikke blevet tildelt endnu</h5>
          </div>
          <div v-if="!group.open" class="shadow p-3 mb-2 bg-white rounded">
            <h5>{{ split(nisse.name) }}s ønskeliste</h5>
            <li v-for="wish in nisse.data" :key="wish.id">
            <WishItem :title="wish.data.title" :description="wish.data.description" :link="wish.data.link" :removeEnabled="false"></WishItem>
          </li>
          </div>
        </div>
        <div v-else class="shadow p-3 mb-2 bg-white rounded">
          <div class="hstack">
            <h5 class="col">Min ønskeliste</h5>
            <button class="btn btn-pri" @click="showModal = true">Opret ønske</button>
          </div>

          <div v-if="wishes.length !== 0">
            <li v-for="wish in wishes" :key="wish.id">
              <WishItem :title="wish.data.title" :description="wish.data.description" :link="wish.data.link" :removeEnabled="true" @removeWish="removeWish(wish.id) " ></WishItem>
            </li>
          </div>
          <div v-else class="my-3">
            <hr/>
            <p class="emp pt-2">Endnu ingen ønsker...</p>
          </div>
          
        </div>
      </div>

      <transition name="modal">
            <ModalComponent v-if="showModal" @close="showModal = false">
            <template v-slot:modal-content>
              <h3 class="pb-3">Opret et ønske</h3>
              <label>Titel</label>
              <input v-model="title" class="form-control mb-3" placeholder="titel på ønske"/>
              <label>Beskrivelse</label>
              <input v-model="description" class="form-control mb-3" placeholder="f.eks. størrelse, farve eller model"/>
              <label>Link</label>
              <input v-model="link" class="form-control mb-5" placeholder="indsæt link"/>
              <button class="btn btn-pri" @click="addWish">Opret</button>
            </template>
          </ModalComponent>
        </transition>

      </div>
      
    </div>
    <div class="footer"></div>
    
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, computed, toRaw } from 'vue'
import Navigation from "@/components/NavigationComponent.vue";
import ModalComponent from '@/components/ModalComponent.vue'
import { useRoute } from 'vue-router';
import WishItem from '@/components/WishItem.vue'

export default {
  
  setup() {
    const route = useRoute()
    const store = useStore()

    const selected = ref(0)

    const showModal = ref(false)
    const title = ref("")
    const description = ref("")
    const link = ref("")
    
    const groupId = ref(route.params.id)
    store.dispatch('fetchGroup', groupId.value)

     const group = computed(() => {
      return store.getters.group;
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const wishes = computed(() => {
      return store.getters.wishes;
    });

    const nisse = computed(() => {
      return store.getters.nisse;
    });

    const getNisse = async () => {
      if (!group.value.open) {
        let list = toRaw(group.value.pairs)
        let currentUserId = user.value.data.uid
        let nisseId = list[currentUserId]

        await store.dispatch('fetchNisse', { userId: nisseId, groupId: groupId.value })
      }
    }

    function split(name) {
      if (name) {
        let firstName = name.split(' ')
        return firstName[0]
      }
    } 

    function shuffle(arr) {
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
      }
      return arr
    }

    function check(arr1, arr2) {
      for (var i = 0; i < arr1.length; ++i) {
        if (arr1[i] === arr2[i]) return false;
      }
      return true;
    }

    const closeGroup = async () => {
      let users1 = Object.keys(toRaw(group.value.members))
      let users2 = Object.keys(toRaw(group.value.members))
 
      while (!check(users1, users2)) {
        shuffle(users2)
      }      
      await store.dispatch('writePair', { users1: users1, users2: users2, groupId: groupId.value })
    }

    const getWishes = async () => {
      try {
        await store.dispatch('fetchWishes', { 
          userId: store.getters.user.data.uid,
          groupId: groupId.value,
        })

      } catch (err) {
        console.log(err)
      }
    }

    const addWish = async () => {
      let newUrl = link.value
      if (link.value) {
        if (!/^https?:\/\//i.test(newUrl)) {
          newUrl = 'http://' + link.value;
        }
      }

      try {
        await store.dispatch('writeWish', {
          groupId: groupId.value,
          userId: store.getters.user.data.uid,
          title: title.value,
          description: description.value,
          link: newUrl
        })
      }
      catch (err) {
        console.log(err)
      }
      showModal.value = false
      title.value = ""
      description.value = ""
      link.value = ""
    }

    const removeWish = async (wishId) => {
      await store.dispatch('removeWish', {
          groupId: groupId.value,
          userId: store.getters.user.data.uid,
          wishId: wishId
        })
    }

    return { 
      showModal, selected, group, groupId, user, title, description, link, addWish, getWishes, wishes, removeWish, closeGroup, nisse, split, getNisse
    }
  }, 
  components: { Navigation, ModalComponent, WishItem },

}

</script>

<style scoped>

.container {
  max-width: 700px;
  text-align: left;
}

.profilePic {
  height: 40px;
  border-radius: 20px;
}

.item-tab {
  color: rgb(211, 15, 15);
  cursor: pointer;
}

h2 {
  color: rgb(211, 15, 15);
  font-weight: bold;
}

h5 {
  font-weight: bold;
}

li {
  text-decoration: none;
  list-style-type: none;
}

i {
  font-size: 40px;
  color: rgb(211, 15, 15);
}

.btn {
  background-color: rgb(211, 15, 15);
  color: white;
  border-radius: 8px;
}

.tab {
  border-bottom: 2px solid rgb(211, 15, 15);
}

.caption {
  font-size: small;
  font-weight: bold;
  color: rgb(211, 15, 15);
}

.active {
  font-weight: bold;
}

@media (max-width: 600px) {
  .mobile-stack { 
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch;
 }
 .mobile-btn {
  align-self: stretch;
 }

 .mobile-space {
  justify-content: space-evenly
 }
}


</style>