<template>
  <hr/>
  <div class="hstack">
    <div class="col">
      <h5 class="fw-bold">{{ title }}</h5>
      <p>{{ description }}</p>
    </div>
    <div>
      <a v-if="link" :href="link" target="_blank">
        <i class="bi bi-link-45deg mx-2"></i>
      </a>
        <i v-if="removeEnabled" @click="$emit('removeWish')" class="bi bi-x-lg ms-4"></i>
    </div>
  </div>
</template>

<script>

export default {
  emits: ["removeWish"],
  props: {
    title: String,
    description: String,
    link: String, 
    removeWish: Promise, 
    removeEnabled: Boolean
  }, 
  setup() {

    return {}
  }
  
}

</script>

<style scoped>

i {
  color: rgb(211, 15, 15);
}

</style>