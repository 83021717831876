<template>
  <DashboardView v-if="user.loggedIn"/>
  <LoginView v-else/>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import LoginView from './LoginView.vue';
import DashboardView from './DashboardView.vue';

export default {
  components: { LoginView, DashboardView },
  
  setup() {
    const store = useStore()

    const user = computed(() => {
      return store.getters.user;
    });

    return { user }
  }
}

</script>