<template>
  <div class="row h-75 mx-auto">
    <div class="w my-auto mx-auto">
      <h1>Create profile</h1>
      <input v-model="name" class="form-control mb-3" placeholder="name">
      <input v-model="email" class="form-control mb-3" placeholder="email">
      <input v-model="password" type="password" class="form-control mb-3" placeholder="password">
   
        <button @click="register" class="btn btn-pri w-100">Sign up</button>
        <hr/>
        <button @click="cancel" class="btn btn-secondary w-100">Cancel</button>
 
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'RegisterView',
  setup() {
    const name = ref("")
    const email = ref("")
    const password = ref("")
    const error = ref(null)

    const store = useStore()
    const router = useRouter()

    const register = async () => {
      try {
        await store.dispatch('register', {
          email: email.value,
          password: password.value,
          name: name.value
        })
        router.push('/dashboard')
      }
      catch (err) {
        error.value = err.message
      }
    }

    const cancel = () => {
        router.push('/')
    }

    return { register, cancel, name, email, password, error }
  }
}
</script>

<style scoped>

.w {
  max-width: 400px;
}

</style>