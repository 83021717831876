<template>
    <div class="container-fluid d-flex flex-column justify-content-between">
    <div>
      <Navigation/>

  <div class="container d-flex justify-content-center">
    <div class="background shadow p-3 mb-2 bg-white rounded mt-5 p-5">
      <h4 v-if="user.data.displayName">{{user.data.displayName}}</h4>
      <p>{{user.data.email}}</p>
      <button @click="signOut" class="btn btn-pri px-5 mt-3">Log ud</button>
    </div>
  </div>
     
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import { useStore} from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
import Navigation from "@/components/NavigationComponent.vue";

export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const user = computed(() => {
            return store.getters.user;
        });
        const signOut = async () => {
            await store.dispatch("logOut");
            router.push("/");
        };
        return { signOut, user };
    },
    components: { Navigation }
}

</script>

<style scoped>

h4 {
  color: rgb(211, 15, 15);
  font-weight: bold;
}

p {
  color: rgb(211, 15, 15);
}


</style>