import { createRouter, createWebHistory } from 'vue-router'
import Register from '../views/RegisterView.vue';
import Dashboard from '../views/DashboardView.vue';
import Profile from '../views/ProfileView.vue'
import Group from '../views/GroupView.vue'
import Main from '../views/MainView.vue'

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/',
    name: 'login',
    component: Main
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/group/:id',
    name: 'group',
    component: Group
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
