import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCMeExdUNRDcCqh_qrZnfVEfZn1Y_0p1xE",
  authDomain: "dearsecretsanta-b4aeb.firebaseapp.com",
  projectId: "dearsecretsanta-b4aeb",
  storageBucket: "dearsecretsanta-b4aeb.appspot.com",
  messagingSenderId: "13582500798",
  appId: "1:13582500798:web:e8896ed0ef68d082062fef",
  databaseURL: "https://dearsecretsanta-b4aeb-default-rtdb.firebaseio.com"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth()

const database = getDatabase(app);

export { app, auth, database }