<template>
  <div class="container row">
      <img alt="Logo" class="logo" src="../assets/logo.svg">
      <input v-model="email" class="form-control mb-3" placeholder="email">
      <input v-model="password" type="password" class="form-control mb-3" placeholder="password">
      <button @click="handleLogIn" class="btn btn-primary mb-3" :disabled="!email||!password">
        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-else>Log ind</span>
      </button>
      <hr/>
      <router-link to="/register" class="btn btn-outline-primary">Opret bruger</router-link>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  name: 'LoginView',
  setup() {
    const email = ref("")
    const password = ref("")
    const error = ref(null)
    
    const loading = ref(false)

    const store = useStore()
    const router = useRouter()

    const handleLogIn = async () => {
      loading.value = true
      try {
        await store.dispatch('logIn', {
          email: email.value,
          password: password.value
        })
        router.push('/dashboard')
      }
      catch (err) {
        error.value = err.message
        console.log(err)
      }
      loading.value = false
    }

    return { handleLogIn, email, password, error, loading }
  }
}
</script>

<style scoped> 
.container {
  max-width: 400px;
  margin: 0px auto;
  padding-top: 30px;
}
.logo {
  height: 300px;
}

.btn-primary {
  background-color: #A80000;
  color: white;
  border-color: none;
}

.btn-outline-primary {
  outline-color: #A80000;
}

</style>
